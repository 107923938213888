import { useContext, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { PageLoader } from "../../context/PageLoaderContext";
import { postRequest } from "../../Services/httpService";
import { apiUrls, SERVICE_VALIDATE, statusCode } from "../../utility/config";

import './validation_suite.css';

const Ifsc = () => {

    const { setShowLoader } = useContext(PageLoader);

    const [value, setValue] = useState('');
    const [apiResponse, setApiResponse] = useState(null);

    const formSubmitHandler = () => {

        if (value === '' || value === undefined || value === null) {
            alert('Please enter a valid IFSC!');
            return;
        }

        setShowLoader(true);

        postRequest({
            service: SERVICE_VALIDATE,
            url: apiUrls.validate.ifsc,
            payload: {
                // clientRefId: `DMO_${new Date().valueOf()}`,
                ifsc: value
            }
        }).then(response => {

            if (response.data.code === statusCode.success) {
                setApiResponse(response.data);
            } else if (response.data.code === statusCode.missing) {
                setApiResponse(response.data.message);
            } else {
                alert(response.data.message);
            }

            setShowLoader(false);
        }).catch(error => {
            console.log('error: ', error);
            setShowLoader(false);
        })
    }


    return (
        <>
            <div id="validation_suite_page">

                <div className='content'>

                    <Row>
                        <Col sm="6" md="6">
                            <h5>IFSC Request:</h5>

                            <div className='form'>
                                {/* <Form> */}
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Enter IFSC: <span className="text-danger">*</span></Form.Label>
                                    <Form.Control type="text" placeholder="Enter..." value={value} onChange={e => setValue(e.target.value)} />
                                </Form.Group>

                                <Button variant="primary" type="submit" className='mt-3 float-end' onClick={formSubmitHandler}>
                                    Submit
                                </Button>
                                {/* </Form> */}

                            </div>
                        </Col>

                        <Col sm="6" md="6">
                            <div className="api_response">
                                <h5>API Response:</h5>
                                <div className='api_response_content'>
                                    {apiResponse && (<pre>{JSON.stringify((apiResponse), null, 4)}</pre>)}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default Ifsc;