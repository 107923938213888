import { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { PageLoader } from '../../context/PageLoaderContext';
import { postRequest } from '../../Services/httpService';
import { apiUrls, SERVICE_VALIDATE, statusCode, userName, userPass } from '../../utility/config';
import { clearStorageAll, storeIdentifierToken } from '../../utility/functions';
import './login.css';


const Login = () => {

    const { setShowLoader, isUserLogin, setIsUserLogin } = useContext(PageLoader);
    const navigate = useNavigate();

    const [userId, setUserId] = useState('');
    const [userPwd, setUserPwd] = useState('');

    useEffect(() => {
        if (!isUserLogin) {
            clearStorageAll();
        }
    }, []);

    const submitFormHandler = () => {
        setShowLoader(true);

        if (userId === userName && userPwd === userPass) {


            postRequest({
                service: SERVICE_VALIDATE,
                url: apiUrls.login.getToken,
            }).then(response => {

                if (response.data.code === statusCode.success) {
                    storeIdentifierToken(response.data.data.token);
                    setIsUserLogin(true);
                    navigate('/home', { replace: true });
                } else {
                    alert(response.data.message);
                }

                setShowLoader(false);
            }).catch(error => {
                console.log('Error: ', error);
            })
        } else {
            alert('Invalid user id or password!');
        }

        setShowLoader(false);
    }

    return (
        <div id="login_page">
            <div className="m-5 header">
                <div className="title">
                    <h1>Login</h1>
                </div>
            </div>

            <div className='content form-login'>

                <Row>
                    <Col>
                        {/* <Form> */}
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>User ID: <span className='text-danger'>*</span></Form.Label>
                            <Form.Control type="text" maxLength="30" placeholder="Enter user id" value={userId} onChange={e => { setUserId(e.target.value) }} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password: <span className='text-danger'>*</span></Form.Label>
                            <Form.Control type="password" maxLength="30" placeholder="Password" value={userPwd} onChange={e => { setUserPwd(e.target.value) }} />
                        </Form.Group>

                        <Button variant="primary" type="submit" className='mt-3 float-end' onClick={submitFormHandler}>
                            Login
                        </Button>
                        {/* </Form> */}
                    </Col>
                </Row>

                <Row className='mt-5'>
                    <Col className='text-center'>
                        <small>
                            Copyright &copy; All Rights reserved. <a href="https://xettle.io" alt="XETTLE" target="_blank">xettle.io</a>
                        </small>
                    </Col>
                </Row>

            </div>
        </div>
    );
}

export default Login;