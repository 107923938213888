const CryptoJS = require("crypto-js");


export const hashHandler = (urlEndPoint) => {

    urlEndPoint = urlEndPoint || '';

    let key = '';
    // let uid = ''
    const iv = window.atob(process.env.REACT_APP_HASH_WEBIV);

    if (fetchIdentifierToken()) {
        key = fetchIdentifierToken();
        // uid = String(uid.accountNumber)
        // uid = uid.replaceAll('"', '').substr(-4)
        // iv = `${process.env.REACT_APP_IV_II}${uid}`
        // console.log('uid', uid, iv)
    } else {
        key = process.env.REACT_APP_HASH_BASE_KEY;
        // iv = process.env.REACT_APP_IV
    }

    const signStr = `${process.env.REACT_APP_HASH_SIGN_I}${urlEndPoint}${process.env.REACT_APP_HASH_SIGN_II}${new Date().getTime()}`

    const hashedSign = generateRequestHash(signStr, window.atob(key), iv)

    // console.log('key iv', window.atob(key), iv, urlEndPoint);
    // console.log('hashedSign', hashedSign, signStr)

    return hashedSign
}


export const generateRequestHash = (word, key, iv) => {

    key = CryptoJS.enc.Utf8.parse(key)
    iv = CryptoJS.enc.Utf8.parse(iv)

    let encrypted = CryptoJS.AES.encrypt(word, key, { iv })
    encrypted = encrypted.toString()

    // let decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv });
    // decrypted = decrypted.toString(CryptoJS.enc.Utf8);

    // console.log(`Criptografado: ${encrypted}`);
    // console.log(`Descriptografado: ${decrypted}`);

    return encrypted
}


export const storeIdentifierToken = (val) => {
    localStorage.setItem('identifierToken', encryptor(val))
}

export const fetchIdentifierToken = () => {
    try {
        const d = localStorage.getItem('identifierToken')
        if (d) {
            return decryptor(d)
        }

        return ''
    } catch (error) {
        // localStorage.clear()
        console.log('fetchIdentifierToken', error)
        return ''
    }
}


export const encryptor = text => {
    const AES_KEY = process.env.REACT_APP_AES_KEY;
    const AES_IV = process.env.REACT_APP_AES_KEY_IV;

    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(text), AES_KEY, { iv: AES_IV }).toString()
    return ciphertext
}

export const decryptor = text => {
    const AES_KEY = process.env.REACT_APP_AES_KEY;
    const AES_IV = process.env.REACT_APP_AES_KEY_IV;

    const bytes = CryptoJS.AES.decrypt(text, AES_KEY, { iv: AES_IV })
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    return decryptedData
}

export const clearStorageAll = () => {
    localStorage.clear();
}