import axios from "axios"
import { baseURL, SERVICE_VALIDATE } from "../utility/config"
import { hashHandler } from "../utility/functions";


export const postRequest = (params) => {
    return new Promise(async (success, reject) => {
        try {

            const endPoint = params.url.split('?')[0].split('/').pop();

            // let userToken = '';
            // if (params?.service === SERVICE_VALIDATE) {
            //     userToken = window.btoa(`${clientIdValidate}:${clientSecretValidate}`);
            // } else {
            //     userToken = window.btoa(`${clientId}:${clientSecret}`);
            // }


            if (params?.contentType !== 'multipart/form-data') {
                params = {
                    url: params.url,
                    payload: {
                        ...params.payload
                        // reference: userAgentInfo()
                    }
                }
            }


            await axios.request({
                validateStatus: (status) => status,
                method: 'POST',
                url: `${baseURL}${params.url}`,
                data: params?.payload,
                headers:
                {
                    // Authorization: userToken ? `Basic ${userToken}` : '',
                    requestHash: hashHandler(endPoint),
                    "Content-Type": (params?.contentType) ? params.contentType : "application/json",
                    accept: "application/json",
                    "Access-Control-Allow-Origin": "*",
                    withCredentials: true,
                    'X-Requested-With': 'XMLHttpRequest'
                    // 'api-key-1': api_key_1,
                    // 'api-key-2': api_key_2
                }
            }).then((response) => {
                success(response)
            }).catch((error) => {
                console.log('postRequest: error', error)
                reject(error)
            })
        } catch (error) {
            console.log('postRequest: error', error)
            reject(error)
        }
    })
}


export const getRequest = (params) => {
    return new Promise(async (success, reject) => {
        try {

            const endPoint = params.url.split('?')[0].split('/').pop();

            // let userToken = '';
            // if (params?.service === SERVICE_VALIDATE) {
            //     userToken = window.btoa(`${clientIdValidate}:${clientSecretValidate}`);
            // } else {
            //     userToken = window.btoa(`${clientId}:${clientSecret}`);
            // }

            await axios.request({
                validateStatus: (status) => status,
                method: 'GET',
                url: `${baseURL}${params.url}`,
                params: (params?.payload),
                headers:
                {
                    // Authorization: userToken ? `Basic ${userToken}` : '',
                    requestHash: hashHandler(endPoint),
                    "Content-Type": "application/json",
                    accept: "application/json",
                    "Access-Control-Allow-Origin": ["https://*.xettle.io"],
                    withCredentials: true,
                    'X-Requested-With': 'XMLHttpRequest'
                    // 'api-key-1': api_key_1,
                    // 'api-key-2': api_key_2
                }
            }).then((response) => {
                success(response)
            }).catch((error) => {
                console.log('getRequest: error', error)
                reject(error)
            })

        } catch (error) {
            console.log('getRequest: try_catch: ', error)
            reject(error)
        }
    })
}