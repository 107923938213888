import { useContext, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { PageLoader } from "../../context/PageLoaderContext";
import { postRequest } from "../../Services/httpService";
import { apiUrls, SERVICE_VALIDATE, statusCode } from "../../utility/config";

import './ocr.css';

const Aadhaar = () => {

    const { setShowLoader } = useContext(PageLoader);

    const [url, setUrl] = useState('');
    const [urlBack, setUrlBack] = useState('');
    const [apiResponse, setApiResponse] = useState(null);

    const formSubmitHandler = () => {
        // alert(`DMO_${new Date().valueOf()}`);

        if (url === '' || url === undefined || url === null ||
            urlBack === '' || urlBack === undefined || urlBack === null) {
            alert('Please enter a valid URL!');
            return;
        }

        setShowLoader(true);

        postRequest({
            service: SERVICE_VALIDATE,
            url: apiUrls.ocr.aadhaar,
            payload: {
                clientRefId: `DMO_${new Date().valueOf()}`,
                aadhaarFront: url,
                aadhaarBack: urlBack
            }
        }).then(response => {

            if (response.data.code === statusCode.success) {
                setApiResponse(response.data);
            } else if (response.data.code === statusCode.missing) {
                setApiResponse(response.data.message);
            } else {
                alert(response.data.message);
            }

            setShowLoader(false);
        })
    }


    return (
        <>
            <div id="ocr_page_pan">

                <div className='content'>

                    <Row>
                        <Col sm="6" md="6">
                            <h5>AADHAAR OCR Request:</h5>

                            <div className='form'>

                                {/* <Form> */}
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>AADHAAR Front URL: <span className="text-danger">*</span></Form.Label>
                                    <Form.Control type="url" placeholder="Enter url" value={url} onChange={e => setUrl(e.target.value)} />
                                </Form.Group>


                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>AADHAAR Back URL: <span className="text-danger">*</span></Form.Label>
                                    <Form.Control type="url" placeholder="Enter url" value={urlBack} onChange={e => setUrlBack(e.target.value)} />
                                </Form.Group>

                                <Button variant="primary" type="submit" className='mt-3 float-end' onClick={formSubmitHandler}>
                                    Submit
                                </Button>
                                {/* </Form> */}

                            </div>
                        </Col>

                        <Col sm="6" md="6">
                            <div className="api_response">
                                <h5>API Response</h5>

                                <div className='api_response_content'>
                                    {apiResponse && (<pre>{JSON.stringify((apiResponse), null, 4)}</pre>)}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default Aadhaar;