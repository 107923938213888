import { useContext, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { PageLoader } from "../../context/PageLoaderContext";
import { postRequest } from "../../Services/httpService";
import { apiUrls, SERVICE_VALIDATE, statusCode } from "../../utility/config";

import './validation_suite.css';


const AadhaarValidation = () => {

    const { setShowLoader } = useContext(PageLoader);

    const [otp, setOtp] = useState('');
    const [value, setValue] = useState('');
    const [apiResponse, setApiResponse] = useState(null);
    const [apiResponseId, setApiResponseId] = useState(null);


    const formSubmitHandler = () => {

        if (value === '' || value === undefined || value === null) {
            alert('Please enter a valid IFSC!');
            return;
        }

        setShowLoader(true);
        setApiResponse(null);

        postRequest({
            service: SERVICE_VALIDATE,
            url: apiUrls.validate.aadhaar.init,
            payload: {
                aadhaarNumber: value
            }
        }).then(response => {

            if (response.data.code === statusCode.success) {
                setApiResponseId(response.data.data.requestId);
                setShowLoader(false);
            } else if (response.data.code === statusCode.missing) {
                setApiResponse(response.data.message);
                setShowLoader(false);
            } else {
                // alert(response.data.message);
                setApiResponse(response.data.message);
                setShowLoader(false);
            }
        }).catch(error => {
            console.log('error: ', error);
            setShowLoader(false);
        })
    }


    const formOtpSubmitHandler = () => {

        if (otp === '' || otp === undefined || otp === null) {
            alert('Please enter valid OTP!');
            return;
        }

        if (apiResponseId === '' || apiResponseId === undefined || apiResponseId === null) {
            alert('Invalid response ID!');
            return;
        }

        setShowLoader(true);
        setApiResponse(null);

        postRequest({
            service: SERVICE_VALIDATE,
            url: apiUrls.validate.aadhaar.status,
            payload: {
                requestId: apiResponseId,
                otp: otp
            }
        }).then(response => {

            if (response.data.code === statusCode.success) {
                setApiResponse(response.data);
                setShowLoader(false);
            } else if (response.data.code === statusCode.missing) {
                setApiResponse(response.data.message);
                setShowLoader(false);
            } else {
                // alert(response.data.message);
                setApiResponse(response.data.message);
                setShowLoader(false);
            }
        }).catch(error => {
            console.log('error: ', error);
            setShowLoader(false);
        })
    }


    return (
        <>
            <div id="validation_suite_page">

                <div className='content'>

                    <Row>
                        <Col sm="6" md="6">
                            <h5>AADHAAR Request:</h5>

                            <div className='form'>

                                {/* <Form> */}
                                {apiResponseId === null && (
                                    <>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Enter AADHAAR Number: <span className="text-danger">*</span></Form.Label>
                                            <Form.Control maxLength="12" type="text" placeholder="Enter..." value={value} onChange={e => setValue(e.target.value)} />
                                        </Form.Group>

                                        <Button variant="primary" type="submit" className='mt-3 float-end' onClick={formSubmitHandler}>
                                            Submit
                                        </Button>
                                    </>
                                )}

                                {apiResponseId !== null && (
                                    <>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Enter OTP: <span className="text-danger">*</span></Form.Label>
                                            <Form.Control maxLength="12" type="text" placeholder="Enter..." value={otp} onChange={e => setOtp(e.target.value)} />
                                        </Form.Group>

                                        <Button variant="primary" type="submit" className='mt-3 float-end' onClick={formOtpSubmitHandler}>
                                            Submit OTP
                                        </Button>

                                        <button type="button" className="btn btn btn-secondary mt-3" onClick={e => { setApiResponseId(null) }}>Reset</button>
                                    </>
                                )}
                                {/* </Form> */}

                            </div>
                        </Col>

                        <Col sm="6" md="6">
                            <div className="api_response">
                                <h5>API Response:</h5>

                                <div className='api_response_content'>
                                    {apiResponse && (<pre>{JSON.stringify((apiResponse), null, 4)}</pre>)}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default AadhaarValidation;